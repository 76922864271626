import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toCurrency } from "../lib/utils";

interface InvestmentListItemProps {
  name: string;
  metadata: string;
  shares: number;
  amount: number;
  id: string;
}

const randomBackgroundColor = () => {
  const colors = [
    "border-black",
    "border-dark-grey",
    "border-cool-grey",
    "border-deep-blue",
    "border-white-beige",
    "border-beige",
    "border-light-blue",
    "border-grey-blue",
    "border-grey",
    "border-light-grey",
  ];
  return colors[Math.floor(Math.random() * colors.length)];
};

const InvestmentListItem: React.FC<InvestmentListItemProps> = ({
  name,
  metadata,
  shares,
  amount,
  id,
}) => {
  const [backgroundStyle, setBackgroundStyle] = useState("deep-blue-20");
  useEffect(() => {
    setBackgroundStyle(randomBackgroundColor());
  }, [name]);
  return (
    <Link to={`/investments/${id}`}>
      <div
        className={`flex items-center justify-between p-4 my-2 bg-white rounded-lg shadow-lg ${backgroundStyle} border-l-8`}
      >
        <div>
          <div className="font-bold">{name}</div>
          <div className="text-gray-500">{metadata}</div>
        </div>
        <div className="text-right">
          <div>{toCurrency(amount)}</div>
          <div>{shares} shares</div>
        </div>
      </div>
    </Link>
  );
};

export default InvestmentListItem;
