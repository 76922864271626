import {
  addDoc,
  collection,
  doc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  QuerySnapshot,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import create from "zustand";
import { auth, firestore, getAssetValue } from "../lib/firebase";
import { Investment } from "../types/investment.type";

interface InvestmentsStoreState {
  loading: boolean;
  allInvestments: Investment[];
  reservations: {
    investment: string;
    numShares: number;
    timestamp: Date;
    waitList: boolean;
  }[];
  getInvestment: (id: string) => Investment | undefined;
  addInvestor: (id: string, numShares: number, uid: string) => Promise<void>;
  reserveShares: (
    id: string,
    numShares: number,
    uid: string,
    waitList?: boolean
  ) => Promise<void>;
}

export const useInvestmentsStore = create<InvestmentsStoreState>(
  (set, get) => ({
    loading: true,
    allInvestments: [],
    reservations: [],
    getInvestment: (id) => get().allInvestments.find((i) => i.id === id),
    reserveShares: async (id, numShares, uid, waitList) => {
      let investment = get().getInvestment(id);
      if (!investment) return;
      investment.reserved.push({
        user: uid,
        timestamp: new Date(),
        numShares: numShares,
        waitList,
      });
      await updateDoc(doc(firestore, "investments", id), {
        reserved: investment.reserved,
      });
    },
    addInvestor: async (id, numShares, uid) => {
      const q = query(
        collection(firestore, "transactions"),
        orderBy("refNumber", "desc"),
        limit(1)
      );
      const qs = await getDocs(q);
      const newestRefNumber = qs.docs[0].get("refNumber");
      const investment: any = get().getInvestment(id);
      await addDoc(collection(firestore, "transactions"), {
        type: investment.status === "open" ? "reserve" : "waitlist",
        user: uid,
        investment: id,
        numShares: numShares,
        refNumber: newestRefNumber + 1,
        timestamp: serverTimestamp(),
      });
    },
  })
);

const investmentsRef = query(
  collection(firestore, "investments")
  // REVIEW IN FUTURE
  // where("closesAt", ">", new Date())
);

// Listen to investments
auth.onAuthStateChanged((user) => {
  if (user) {
    onSnapshot(investmentsRef, async (querySnapshot: QuerySnapshot) => {
      useInvestmentsStore.setState({ loading: true });
      let investments: any = [];
      querySnapshot.forEach((doc: any) => {
        investments.push(toInvestment(doc));
      });
      investments = await Promise.all(investments);
      const filteredInvestments: any = [];
      let reservations: any[] = [];

      investments.forEach((investment: Investment) => {
        const links = investment.reserved?.filter((r) => r.user === user.uid);
        if (!links) return;
        if (!filteredInvestments.find((i: any) => i.id === investment.id)) {
          filteredInvestments.push(investment);
        }
        if (["open", "closed"].indexOf(investment.status) === -1) return;
        reservations = [
          ...reservations,
          ...links.map((l) => ({ ...l, investment: investment.id })),
        ];
      });
      useInvestmentsStore.setState({
        allInvestments: investments,
        reservations,
        loading: false,
      });
    });
  }
});

async function toInvestment(doc: any): Promise<Investment> {
  let investment = { ...doc.data(), id: doc.id };
  investment.closesAt = investment.closesAt.toDate();
  investment.opensAt = investment.opensAt.toDate();
  investment.availableShares =
    investment.totalShares - investment.reservedShares;
  let assets: any[] = [];
  investment.assets.forEach(
    (a: any) => (assets = [...assets, getAssetValue(a)])
  );
  assets = await Promise.all(assets);
  investment.assets = assets;
  return investment as Investment;
}
