import { IonItem, IonLabel, IonSelect, IonSelectOption } from "@ionic/react";
import { useField } from "formik";

interface InputProps {
  label: string;
  name: string;
  options: { label: string; value: string }[];
}
const SelectInput: React.FC<InputProps> = ({ label, options, ...props }) => {
  const [field] = useField(props);
  return (
    <IonItem>
      <IonLabel position="floating">{label}</IonLabel>
      <IonSelect {...props} onIonChange={field.onChange} value={field.value}>
        {options.map((op) => (
          <IonSelectOption value={op.value} key={op.value}>
            {op.label}
          </IonSelectOption>
        ))}
      </IonSelect>
    </IonItem>
  );
};
export default SelectInput;
