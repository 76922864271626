import { IonRouterLink } from "@ionic/react";
import { useParams } from "react-router";
import CheckIcon from "../components/CheckIcon";
import Layout from "../components/Layout";
import { toCurrency } from "../lib/utils";
import { useInvestmentsStore } from "../stores/investments.store";
import { useTransactionsStore } from "../stores/transactions.store";

const UserInvestment: React.FC = () => {
  const { transactionId } = useParams<{
    transactionId: string;
  }>();
  const getTransaction = useTransactionsStore((state) => state.getTransaction);
  const transaction = getTransaction(transactionId);
  const investment = useInvestmentsStore((state) =>
    state.allInvestments.find((i) => i.id === transaction?.investment)
  );

  if (!investment || !transaction) {
    return <div>Not found</div>;
  }
  return (
    <Layout backButton hideNavBar>
      <div className="text-center text-xl">
        Your investment on
        <span className="text-primary"> {investment.name}</span>
      </div>
      <div className="text-center my-2">
        <IonRouterLink routerLink={`/investments/${investment.id}`}>
          View this investment
        </IonRouterLink>
      </div>
      <div className="text-center text-lg">
        <p>
          You {investment.status === "active" ? "own" : "reserved"}{" "}
          <span className="text-primary">{transaction.numShares}</span> shares
          of this investment
        </p>
        <p>
          with a total value of{" "}
          <span className="text-primary">
            {toCurrency(investment.shareValue * transaction.numShares)}
          </span>
        </p>
        <p className="section-header mt-2">Status</p>
        {investment.status === "closed" && (
          <div className="mx-4">
            <p className="text-left text-sm">
              This investment waiting to become active. Please complete your
              payment and documentation. You will be notified when the
              investment becomes active.
            </p>

            <div className="mt-4">
              <div className="flex justify-between items-center">
                <p>Payment</p>
                <CheckIcon check={transaction.status.payment.complete} />
              </div>
              <div className="flex justify-between items-center">
                <p>Documentation</p>
                <CheckIcon check={transaction.status.documentation.complete} />
              </div>
            </div>
          </div>
        )}
        {investment.status === "active" && <div>This investment is active</div>}
        {
          //TODO: Add timeline
          //TODO: add and view files, share certificates, etc.
          //TODO: view payouts
        }
      </div>
    </Layout>
  );
};
export default UserInvestment;
