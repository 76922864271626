import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonSpinner,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { basket, person, pieChart } from "ionicons/icons";
import { Redirect, Route } from "react-router-dom";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";

/* Theme variables */
import "./main.css";
import PrivateRoute from "./router/PrivateRoute";
import PublicRoute from "./router/PublicRoute";
import routes from "./router/routes";
import { useAppBarStore } from "./stores/appbar.store";
import { useAuthStore } from "./stores/auth.store";
import "./theme/variables.css";

setupIonicReact();

const App: React.FC = () => {
  const [auth, loading] = useAuthStore((state: any) => [
    state.auth,
    state.loading,
  ]);
  const appBarVisible = useAppBarStore((state) => state.visible);
  return (
    <IonApp>
      {loading ? (
        <div className="flex items-center justify-center h-full">
          <IonSpinner color="primary" className="w-12 h-12" />
        </div>
      ) : (
        <IonReactRouter>
          <IonTabs>
            <IonRouterOutlet>
              <Route path="">
                <Redirect to="/investments" />
              </Route>
              {routes.map((route) => {
                return route.public ? (
                  <PublicRoute
                    component={route.component}
                    path={route.path}
                    key={route.path}
                    exact
                  />
                ) : (
                  <PrivateRoute
                    component={route.component}
                    path={route.path}
                    key={route.path}
                    exact
                  />
                );
              })}
            </IonRouterOutlet>
            <IonTabBar slot="bottom" hidden={!appBarVisible} className="py-4">
              <IonTabButton tab="portfolio" href="/portfolio" disabled={!auth}>
                <IonIcon icon={pieChart} />
                <IonLabel>Portfolio</IonLabel>
              </IonTabButton>
              <IonTabButton
                tab="investments"
                href="/investments"
                disabled={!auth}
              >
                <IonIcon icon={basket} />
                <IonLabel>Marketplace</IonLabel>
              </IonTabButton>
              <IonTabButton tab="profile" href="/profile" disabled={!auth}>
                <IonIcon icon={person} />
                <IonLabel>Profile</IonLabel>
              </IonTabButton>
            </IonTabBar>
          </IonTabs>
        </IonReactRouter>
      )}
    </IonApp>
  );
};

export default App;
