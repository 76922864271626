import { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

interface PDFViewerInterface {
  file: Blob;
}

export const PDFViewer: React.FC<PDFViewerInterface> = ({ file }) => {
  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);
  const [numPages, setNumPages] = useState(0);
  return (
    <Document
      file={file}
      onLoadSuccess={({ numPages }) => setNumPages(numPages)}
    >
      {Array.from(new Array(numPages), (el, index) => (
        <Page pageNumber={index + 1} key={index} />
      ))}
    </Document>
  );
};
