import { Route, Redirect } from "react-router";
import { useAuthStore } from "../stores/auth.store";

const PublicRoute = ({ component: Component, restricted, ...params }: any) => {
  const auth = useAuthStore((state: any) => state.auth);
  return (
    <Route
      {...params}
      render={(props: any) =>
        !!auth && restricted ? <Redirect to="" /> : <Component {...props} />
      }
    />
  );
};

export default PublicRoute;
