import { IonButton, IonCard, IonImg, IonSpinner } from "@ionic/react";
import { RouteComponentProps } from "react-router";
import { Swiper, SwiperSlide } from "swiper/react";
import Layout from "../components/Layout";
import { useInvestmentsStore } from "../stores/investments.store";

import { useEffect, useState } from "react";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import FileViewer from "../components/FileViewer";
import { toCurrency } from "../lib/utils";

interface AssetSinglePageProps
  extends RouteComponentProps<{
    investmentId: string;
    assetId: string;
  }> {}

const AssetSingle: React.FC<AssetSinglePageProps> = ({ match }) => {
  const investmentId = match?.params?.investmentId;
  const assetId = match?.params?.assetId;

  const [references, setReferences] = useState<any>({ investmentId, assetId });

  const asset = useInvestmentsStore((state) =>
    state
      .getInvestment(references?.investmentId)
      ?.assets.find((a) => a.id === references?.assetId)
  );

  useEffect(() => {
    if (!investmentId || !assetId) {
      const tmpInvestmentId = window.location.href.split("/").slice(-3)[0];
      const tmpAssetId = window.location.href.split("/").slice(-1)[0];
      setReferences({ investmentId: tmpInvestmentId, assetId: tmpAssetId });
    }
  }, [investmentId, assetId]);

  if (!asset) return <IonSpinner />;

  return (
    <Layout title={asset.name} backButton>
      <div className="px-4 py-8">
        <div className="section-heading">Information</div>
        <IonCard
          className="section-card relative"
          style={{ padding: 0, margin: 0 }}
        >
          <Swiper
            style={{ width: "100%", height: "100%" }}
            pagination={true}
            navigation={true}
            modules={[Pagination, Navigation]}
          >
            {asset.images.map((img, index) => (
              <SwiperSlide
                key={index}
                style={{
                  width: "100%",
                  height: "300px",
                  padding: 0,
                  margin: 0,
                }}
              >
                <IonImg
                  src={img}
                  style={{ width: "100%", height: "300px", objectFit: "cover" }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="mx-4">
            <div className="section-heading">Description</div>
            <div>{asset.description}</div>
            <div className="flex flex-col w-full my-8">
              <div className="flex w-full justify-between">
                <div>Square meters:</div>
                <div className="w-1/2 text-grey-blue">{asset.squareMeters}</div>
              </div>
              <div className="flex w-full justify-between">
                <div>Year built:</div>
                <div className="w-1/2 text-grey-blue">{asset.yearBuilt}</div>
              </div>
              <div className="flex w-full justify-between">
                <div>Value:</div>
                <div className="w-1/2 text-grey-blue">
                  {toCurrency(asset.value)}
                </div>
              </div>
              <div className="flex w-full justify-between">
                <div>Purchase terms:</div>
                <div className="w-1/2 text-grey-blue">
                  {asset.purchaseTerms}
                </div>
              </div>
            </div>
          </div>
        </IonCard>
        <div className="section-heading">Location</div>
        <IonCard className="section-card">
          <div className="flex flex-col w-full">
            <div className="flex w-full justify-between">
              <div>Address</div>
              <div className="w-1/2 text-grey-blue">{asset.address}</div>
            </div>
            <div className="flex w-full justify-between">
              <div>City</div>
              <div className="w-1/2 text-grey-blue">{asset.city}</div>
            </div>
            <div className="flex w-full justify-between">
              <div>Area</div>
              <div className="w-1/2 text-grey-blue">{asset.area}</div>
            </div>
            <div className="flex w-full justify-between">
              <div>Country</div>
              <div className="w-1/2 text-grey-blue">{asset.country}</div>
            </div>
            <div className="flex w-full justify-between">
              <div>Postal code</div>
              <div className="w-1/2 text-grey-blue">{asset.postalcode}</div>
            </div>
            <IonButton
              className="mt-8"
              href={asset.streetViewUrl}
              target="_blank"
            >
              View on map
            </IonButton>
          </div>
        </IonCard>
        <div className="section-heading">Features</div>
        <div className="flex gap-x-2">
          {asset.features.map((f, index) => (
            <div
              key={"feature-" + index}
              className="bg-white rounded-full py-2 px-4 text-deep-blue"
            >
              {f}
            </div>
          ))}
        </div>
        <div className="section-heading">Attachments</div>
        <FileViewer files={asset.documents} />
      </div>
    </Layout>
  );
};
export default AssetSingle;
