import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import { toCurrency } from "../lib/utils";
Chart.register(ArcElement, Tooltip, Legend);

const PortfolioValueCard = ({
  portfolioValue,
  investmentValue,
  reservationsValue,
}: {
  portfolioValue: number;
  investmentValue: number;
  reservationsValue: number;
}) => {
  const data = {
    labels: ["Investment Value", "Reservations Value"],
    datasets: [
      {
        data: [investmentValue, reservationsValue],
        backgroundColor: ["#A9BFD6", "#F3F1EC"],
        borderColor: ["#A9BFD6", "#F3F1EC"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutoutPercentage: 80,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className="shadow-xl rounded-2xl p-4 bg-white flex flex-col items-center">
      <div className="flex justify-between w-full">
        <div className="w-1/2">
          <Doughnut data={data} options={options} />
        </div>
        <div className="w-1/2 flex flex-col justify-center items-start pl-4">
          <div className="text-sm">
            <p className="text-lg">Investment Value</p>
            <p className="font-semibold text-deep-blue text-xl">
              {toCurrency(investmentValue)}
            </p>
          </div>
          <div className="text-sm mt-4">
            <p className="text-grey-blue text-lg">Reservations Value</p>
            <p className="font-semibold text-deep-blue text-xl">
              {toCurrency(reservationsValue)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortfolioValueCard;
