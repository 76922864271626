import {
  IonButton,
  IonCard,
  IonIcon,
  IonItem,
  IonLabel,
  IonText,
} from "@ionic/react";
import { Formik } from "formik";
import { pencilSharp } from "ionicons/icons";
import { useState } from "react";
import { toast } from "react-hot-toast";
import TextInput from "../components/forms/TextInput";
import { getBasicInformationValidationSchema } from "../components/getValidationSchema";
import validate from "../components/validate";
import { updateDocument } from "../lib/firebase";
import { useAuthStore } from "../stores/auth.store";

const BasicInformationForm: React.FC = () => {
  const [edit, setEdit] = useState(false);
  const [auth, user] = useAuthStore((state) => [state.auth, state.user]);
  if (!auth) {
    return null;
  }

  const initialValues = {
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    phoneNumber: user?.phoneNumber || "",
    email: user?.email || "",
  };

  return (
    <>
      <IonCard>
        <IonItem color="secondary" button routerLink="/profile/verification">
          <IonLabel>Verification</IonLabel>
          <IonText slot="end">
            {user.verificationStatus || "not verified"}
          </IonText>
        </IonItem>
      </IonCard>

      <IonCard className="border p-2 shadow-xl rounded-2x bg-white">
        <IonItem className="section-header font-bold" lines="none">
          Basic information{" "}
          <IonButton
            slot="end"
            fill={edit ? "solid" : "outline"}
            className="w-16"
            onClick={() => setEdit(!edit)}
          >
            <IonIcon slot="start" icon={pencilSharp} />
            <IonLabel>{edit ? "ON" : "OFF"}</IonLabel>
          </IonButton>
        </IonItem>

        <Formik
          initialValues={initialValues}
          onSubmit={async (values) => {
            const temp = updateDocument("users", auth.uid, {
              ...values,
            });
            toast.promise(temp, {
              loading: "Saving information",
              error: "Something went wrong",
              success: "Information saved!",
            });
            await temp;
            setEdit(false);
          }}
          validate={validate(getBasicInformationValidationSchema)}
        >
          {({ submitForm, isSubmitting, resetForm }) => (
            <form>
              <TextInput
                name="firstName"
                label="First Name"
                placeholder="Example"
                readonly={!edit}
              />
              <TextInput
                name="lastName"
                label="Last Name"
                placeholder="Example"
                readonly={!edit}
              />
              <TextInput
                name="phoneNumber"
                label="Phone Number"
                placeholder="000 000 0000"
                type="tel"
                readonly={!edit || user?.authProvider === "phone"}
              />
              <TextInput
                name="email"
                label="Email"
                placeholder="example@example.com"
                type="email"
                readonly={!edit || user?.authProvider !== "phone"}
              />

              <div className="text-center mt-4">
                <IonButton
                  fill="outline"
                  className="w-24"
                  onClick={() => {
                    setEdit(false);
                    resetForm();
                  }}
                  disabled={isSubmitting}
                  hidden={!edit}
                >
                  Cancel
                </IonButton>
                <IonButton
                  fill="solid"
                  className="w-24"
                  onClick={submitForm}
                  disabled={isSubmitting}
                  hidden={!edit}
                >
                  Save
                </IonButton>
              </div>
            </form>
          )}
        </Formik>
      </IonCard>
    </>
  );
};

export default BasicInformationForm;
