import { onAuthStateChanged, User as FirebaseUser } from "firebase/auth";
import create from "zustand";
import { auth, getUserSnapshot } from "../lib/firebase";
import { collection, onSnapshot } from "firebase/firestore";
import { firestore } from "../lib/firebase";
interface AuthState {
  auth: FirebaseUser | null;
  loading: boolean;
  user: any;
  whitelist: any[];
  clearAuth: () => void;
}

export const useAuthStore = create<AuthState>((set) => ({
  auth: null,
  clearAuth: () => set({ auth: null }),
  loading: true,
  user: {},
  whitelist: [],
}));

onAuthStateChanged(auth, (user) => {
  useAuthStore.setState({ loading: true });
  if (user) {
    useAuthStore.setState({ auth: user });
    getUserSnapshot(user.uid, (usersnap: any) => {
      if (usersnap) {
        useAuthStore.setState({ auth: user, user: usersnap, loading: false });
      } else {
        useAuthStore.setState({ auth: null, user: null, loading: false });
      }
    });
  } else {
    useAuthStore.setState({ auth: null, user: null, loading: false });
  }
});

export const getWhitelistSnapshot = (callback: any) => {
  onSnapshot(collection(firestore, "whitelist"), (snapshot) => {
    const whitelist: any[] = [];
    snapshot.forEach((doc) => {
      whitelist.push({ ...doc.data(), id: doc.id });
    });
    callback(whitelist);
  });
};

getWhitelistSnapshot((whitelist: any) => {
  useAuthStore.setState({ whitelist: whitelist });
});
