import { getBlob, ref } from "@firebase/storage";
import { IonIcon, IonLabel, useIonModal } from "@ionic/react";
import {
  arrowForward,
  documentOutline,
  documentTextOutline,
  imageOutline,
} from "ionicons/icons";
import { useState } from "react";
import { storage } from "../lib/firebase";
import { UserFile } from "../types/userFile.type";
import { FileModal } from "./FileModal";

interface FileViewerProps {
  files: UserFile[];
}
const FileViewer: React.FC<FileViewerProps> = ({ files }) => {
  const [file, setFile] = useState<UserFile>();
  const [currentFile, setCurrentFile] = useState<any>();

  const handleDismiss = () => {
    dismiss();
  };

  const [present, dismiss] = useIonModal(FileModal, {
    file: currentFile,
    fileObject: file,
    onDismiss: handleDismiss,
  });

  const displayFile = async (file: UserFile) => {
    setCurrentFile(undefined);
    setFile(file);
    present();
    const storageRef = ref(storage, file.path);
    const blob = await getBlob(storageRef);
    setCurrentFile(blob);
  };
  return (
    <div className="flex flex-col gap-y-2 py-2">
      {files.map((file: UserFile, index: number) => {
        const filepath = file.path;
        const fileExtention = filepath.split(".").pop();
        const fileIcon = () => {
          switch (fileExtention) {
            case "pdf":
              return documentTextOutline;
            case "png":
              return imageOutline;
            case "jpg":
              return imageOutline;
            default:
              return documentOutline;
          }
        };

        return (
          <div
            key={filepath}
            onClick={() => displayFile(file)}
            className="section-card flex items-center"
          >
            <IonIcon slot="start" icon={fileIcon()} className="w-6 h-6" />
            <div className="ml-4">
              <IonLabel className="text-headline text-dark-grey">
                {file.name}
              </IonLabel>
              <div className="text-sm text-gray-500">{file.description}</div>
            </div>
            <IonIcon icon={arrowForward} className="ml-auto mr-0 text-xl" />
          </div>
        );
      })}
    </div>
  );
};

export default FileViewer;
