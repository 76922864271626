import { IonCheckbox, IonItem, IonLabel, IonText } from "@ionic/react";
import { useField } from "formik";

interface CheckInputProps {
  label: string;
  name: string;
  disabled?: boolean;
}

const CheckInput: React.FC<CheckInputProps> = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props);
  return (
    <>
      <IonItem>
        <IonLabel className="ion-text-wrap">{label}</IonLabel>
        <IonCheckbox
          slot="start"
          {...props}
          onIonChange={(e) => helpers.setValue(e.detail.checked)}
          checked={field.value}
          disabled={props.disabled}
        />
      </IonItem>
      <IonText className="text-red-600 text-sm pl-6">
        {meta.touched && meta.error}
      </IonText>
    </>
  );
};

export default CheckInput;
