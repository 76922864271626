import { Route, Redirect } from "react-router-dom";
import { useAuthStore } from "../stores/auth.store";

const PrivateRoute = ({ component: Component, ...params }: any) => {
  const auth = useAuthStore((state: any) => state.auth);
  return (
    <Route
      {...params}
      render={(props) =>
        !!auth ? <Component {...props} /> : <Redirect to="/auth" />
      }
    />
  );
};

export default PrivateRoute;
