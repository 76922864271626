import Auth from "../pages/Auth";
import Portfolio from "../pages/Portfolio";
import Investments from "../pages/Investments";
import Profile from "../pages/Profile";
import InvestmentSingle from "../pages/InvestmentSingle";
import AssetSingle from "../pages/AssetSingle";
import UserInvestment from "../pages/UserInvestment";
import Verification from "../pages/Verification";

const routes = [
  {
    path: "/auth",
    component: Auth,
    public: true,
  },
  {
    path: "/portfolio",
    component: Portfolio,
  },
  {
    path: "/investments",
    component: Investments,
  },
  {
    path: "/investments/:id",
    component: InvestmentSingle,
  },
  {
    path: "/investments/:investmentId/assets/:assetId",
    component: AssetSingle,
  },
  {
    path: "/profile",
    component: Profile,
  },
  {
    path: "/portfolio/transactions/:transactionId",
    component: UserInvestment,
  },
  {
    path: "/profile/verification",
    component: Verification,
  },
];

export default routes;
