import { IonButton } from "@ionic/react";
import { Formik } from "formik";
import { toast } from "react-hot-toast";
import { Redirect, useHistory } from "react-router";
import CheckInput from "../components/forms/CheckInput";
import TextInput from "../components/forms/TextInput";
import { getBasicInformationValidationSchema } from "../components/getValidationSchema";
import Layout from "../components/Layout";
import SignInForm from "../components/SignInForm";
import validate from "../components/validate";
import { updateDocument } from "../lib/firebase";
import { useAuthStore } from "../stores/auth.store";

const Auth: React.FC = () => {
  const auth = useAuthStore((state: any) => state.auth);
  const user = useAuthStore((state: any) => state.user);

  if (!auth) {
    return <SignInForm />;
  }
  if (!user?.completedBasicInformation) {
    return <BasicInformationForm />;
  }

  return <Redirect to="/investments" />;
};

const BasicInformationForm: React.FC = () => {
  const auth = useAuthStore((state: any) => state.auth);
  const history = useHistory();
  return (
    <Layout title="Personal info" hideNavBar>
      <Formik
        initialValues={{
          firstName: auth?.displayName?.split(" ")[0] || "",
          lastName: auth?.displayName?.split(" ")[1] || "",
          phoneNumber: auth?.phoneNumber || "",
          email: auth?.email || "",
          notifyMe: true,
          consent: false,
          olderThan18: false,
        }}
        onSubmit={async (values) => {
          const temp = updateDocument("users", auth.uid, {
            ...values,
            completedBasicInformation: true,
          });
          toast.promise(temp, {
            loading: "Saving information",
            error: "Something went wrong",
            success: "Information saved!",
          });
          await temp;
          toast(
            "Please verify your account to start investing. An email will follow with more information",
            { duration: 5000 }
          );
          history.push("/profile");
        }}
        validate={validate(getBasicInformationValidationSchema)}
      >
        {({ submitForm, isSubmitting }) => (
          <form className="mt-5">
            <TextInput
              name="firstName"
              label="First Name"
              placeholder="First Name"
            />
            <TextInput
              name="lastName"
              label="Last Name"
              placeholder="Last Name"
            />
            {auth?.providerData[0]?.providerId === "phone" ? (
              <TextInput
                name="email"
                label="Email"
                placeholder="Email Address"
              />
            ) : (
              <TextInput
                name="phoneNumber"
                label="Phone Number"
                placeholder="+27xxxxxxxxx"
              />
            )}
            <CheckInput
              name="olderThan18"
              label="I am older than 18 years old"
            />
            <CheckInput
              name="consent"
              label="I accept the terms and conditions"
            />
            <CheckInput
              name="notifyMe"
              label="Notify me about new investments"
            />
            <div className="text-center mt-4">
              <IonButton
                fill="solid"
                onClick={submitForm}
                disabled={isSubmitting}
              >
                Save
              </IonButton>
            </div>
          </form>
        )}
      </Formik>
    </Layout>
  );
};

export default Auth;
