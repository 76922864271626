import { TextFieldTypes } from "@ionic/core";
import {
  IonInput,
  IonItem,
  IonLabel,
  IonText,
  IonTextarea,
} from "@ionic/react";
import { useField } from "formik";
import { fromCurrency, toCurrency } from "../../lib/utils";

interface InputProps {
  label: string;
  name: string;
  placeholder: string;
  type?: TextFieldTypes | "currency";
  textArea?: boolean;
  disabled?: boolean;
  readonly?: boolean;
}
const TextInput: React.FC<InputProps> = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props);

  const renderInput = () => {
    if (props.textArea) {
      return (
        <IonTextarea
          {...props}
          onIonChange={field.onChange}
          value={field.value}
          autoCorrect="on"
          onBlur={field.onBlur}
          rows={3}
          autoGrow
          disabled={props.disabled}
          readonly={props.readonly}
        />
      );
    } else if (props.type === "currency") {
      return (
        <IonInput
          {...props}
          type="text"
          onIonChange={(e) => {
            helpers.setValue(fromCurrency(e.target.value as string));
          }}
          value={toCurrency(field.value)}
          autoCorrect="on"
          onBlur={field.onBlur}
          disabled={props.disabled}
          readonly={props.readonly}
        />
      );
    } else {
      return (
        <IonInput
          {...props}
          type={props.type}
          onIonChange={field.onChange}
          value={field.value}
          autoCorrect="on"
          onBlur={field.onBlur}
          disabled={props.disabled}
          readonly={props.readonly}
          color="primary"
        />
      );
    }
  };

  return (
    <>
      <IonItem>
        <IonLabel position="stacked" color="primary">
          {label}
        </IonLabel>
        {renderInput()}
      </IonItem>
      <IonText className="text-error text-sm pl-6">
        {meta.touched && meta.error}
      </IonText>
    </>
  );
};

export default TextInput;
