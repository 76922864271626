import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import { useAppBarStore } from "../stores/appbar.store";
import { arrowBack } from "ionicons/icons";

interface LayoutProps {
  title?: string;
  backButton?: boolean;
  hideNavBar?: boolean;
  hideToolbar?: boolean;
  footer?: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({
  title = "",
  backButton = false,
  hideNavBar = false,
  hideToolbar = false,
  footer = null,
  children,
}) => {
  const showAppBar = useAppBarStore((state) => state.show);

  useIonViewWillEnter(() => showAppBar(!hideNavBar));

  return (
    <IonPage>
      {!hideToolbar && (
        <IonToolbar className="shadow-lg">
          {backButton && (
            <IonButtons slot="start">
              <IonBackButton
                defaultHref="/investments"
                text=""
                icon={arrowBack}
                className="text-sm mx-2"
              />
            </IonButtons>
          )}
          <IonTitle className="text-xl text-headline pl-24">{title}</IonTitle>
        </IonToolbar>
      )}
      <IonContent fullscreen>
        <div className="max-w-lg mx-auto">{children}</div>
      </IonContent>
      {footer && <IonFooter>{footer}</IonFooter>}
    </IonPage>
  );
};

export default Layout;
