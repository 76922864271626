import { IonButton } from "@ionic/react";
import Layout from "../components/Layout";
import { useInvestmentsStore } from "../stores/investments.store";
import { useTransactionsStore } from "../stores/transactions.store";
import PortfolioValueCard from "../components/PortfolioValue";
import InvestmentListItem from "../components/InvestmentListItem";
import { useEffect, useState } from "react";

const calculateTotalValue = (items: any, getInvestment: any) => {
  return items.reduce((total: number, item: any) => {
    const investment = getInvestment(item.investment);
    return investment ? total + item.numShares * investment.shareValue : total;
  }, 0);
};

const Portfolio: React.FC = () => {
  const getInvestment = useInvestmentsStore((state) => state.getInvestment);
  const reservations = useInvestmentsStore((state) => state.reservations);
  const transactions = useTransactionsStore((state) => state.transactions);
  const [activeInvestments, setActiveInvestments] = useState([]);
  const [activeReservedInvestments, setActiveReservedInvestments] = useState(
    []
  );
  const [reservedPortfolioValue, setReservedPortfolioValue] = useState(0);
  const [transactionsPortfolioValue, setTransactionsPortfolioValue] =
    useState(0);
  const [portfolioValue, setPortfolioValue] = useState(0);
  const [waitlistedInvestments, setWaitlistedInvestments] = useState([]);
  const [waitlistedPortfolioValue, setWaitlistedPortfolioValue] = useState(0);

  useEffect(() => {
    const waitlistedReservations = reservations.filter((r) => r.waitList);

    const waitlistedInvestmentIds: any = Array.from(
      new Set(waitlistedReservations.map((r) => r.investment))
    )
      .map(getInvestment)
      .filter(Boolean);

    let waitlistedValue = calculateTotalValue(
      waitlistedReservations,
      getInvestment
    );

    setWaitlistedInvestments(waitlistedInvestmentIds);
    setWaitlistedPortfolioValue(waitlistedValue);

    const activeReservations = reservations.filter(
      (r) => !r.waitList && r.numShares > 0
    );

    const investments: any = Array.from(
      new Set(activeReservations.map((r) => r.investment))
    )
      .map(getInvestment)
      .filter(Boolean);

    const investmentsTransactions: any = Array.from(
      new Set(transactions.map((t) => t.investment))
    )
      .map(getInvestment)
      .filter(Boolean);

    // Calculate the total value of transacted shares per investment
    const transactedSharesValue = transactions.reduce(
      (acc: number, transaction: any) => {
        const investment = getInvestment(transaction.investment);
        return investment
          ? acc + transaction.numShares * investment.shareValue
          : acc;
      },
      0
    );

    // Calculate the total value of reserved shares, not accounting for transactions yet
    let rawReservedValue = calculateTotalValue(
      activeReservations,
      getInvestment
    );

    // Deduct the value of transacted shares from the raw reserved value
    const adjustedReservedValue = rawReservedValue - transactedSharesValue;

    setActiveReservedInvestments(investments);
    setActiveInvestments(investmentsTransactions);
    setReservedPortfolioValue(Math.max(adjustedReservedValue, 0));
    setTransactionsPortfolioValue(transactedSharesValue);
    setPortfolioValue(
      Math.max(transactedSharesValue + adjustedReservedValue, 0)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactions, reservations, getInvestment]);

  const getInvestmentSummary = (investment: any) => {
    if (!investment) return null;
    const investmentTransactions =
      transactions?.filter((t: any) => t.investment === investment.id) || [];
    const totalShares =
      investmentTransactions.reduce(
        (curr: number, t: any) => curr + t.numShares,
        0
      ) || 0;
    const totalValue = totalShares * investment.shareValue;
    return (
      <InvestmentListItem
        key={"investment-" + investment.id}
        name={investment.name}
        metadata={investment.yield + "% yield"}
        shares={totalShares}
        amount={totalValue}
        id={investment.id}
      />
    );
  };

  const getReservedSummary = (investment: any) => {
    const investmentTransactions = transactions.filter(
      (t: any) => t.investment === investment.id
    );

    const investmentReservations = reservations.filter(
      (r: any) => r.investment === investment.id && !r.waitList
    );

    const totalTransactedShares = investmentTransactions.reduce(
      (curr: number, t: any) => curr + t.numShares,
      0
    );

    const totalReservedShares = investmentReservations.reduce(
      (curr: number, r: any) => curr + r.numShares,
      0
    );

    // Subtract the transacted shares from the reserved shares
    const adjustedReservedShares = totalReservedShares - totalTransactedShares;

    const totalValue = adjustedReservedShares * investment.shareValue;

    return (
      adjustedReservedShares > 0 && (
        <InvestmentListItem
          key={"reserved-" + investment.id}
          name={investment.name}
          metadata={investment.yield + "% yield"}
          shares={adjustedReservedShares}
          amount={totalValue}
          id={investment.id}
        />
      )
    );
  };

  return (
    <Layout title="Portfolio" hideToolbar>
      <div className="px-4 pt-8">
        <div className="text-black text-headline text-2xl pb-4">Portfolio</div>
        <PortfolioValueCard
          portfolioValue={portfolioValue}
          investmentValue={transactionsPortfolioValue}
          reservationsValue={reservedPortfolioValue}
        />

        <div className="text-black text-headline text-lg py-4">
          My Investments
        </div>

        <div>
          {transactions.length === 0 ? (
            <div className="text-center mt-5">
              <p className="text-lg py-2">
                You have not invested in anything...
              </p>
              <IonButton routerLink="/investments" expand="block">
                Invest now
              </IonButton>
            </div>
          ) : (
            <div className="mx-2 flex flex-col">
              <div>
                {activeInvestments.length === 0 ? (
                  <div className="text-sm text-center">
                    You have no active investments
                  </div>
                ) : (
                  activeInvestments.map((investment) =>
                    getInvestmentSummary(investment)
                  )
                )}
              </div>
            </div>
          )}
        </div>
        <div>
          <div className="text-black text-headline text-lg py-4">
            My Reservations
          </div>
          {activeReservedInvestments.length === 0 ? (
            <div className="text-center mt-5">
              <p className="text-lg py-2">You have no reservations...</p>
              <IonButton routerLink="/investments" expand="block">
                Invest now
              </IonButton>
            </div>
          ) : (
            <div>
              {activeReservedInvestments.map((investment) =>
                getReservedSummary(investment)
              )}
            </div>
          )}
        </div>
        {waitlistedInvestments.length !== 0 && (
          <div>
            <div className="text-black text-headline text-lg py-4">
              My Waitlisted Reservations
            </div>
            <div>
              {waitlistedInvestments.map(
                (investment: any) =>
                  (investment.status === "open" ||
                    investment.status === "closed") && (
                    <InvestmentListItem
                      key={"waitlisted-" + investment.id}
                      name={investment.name}
                      metadata={investment.yield + "% yield"}
                      shares={
                        investment.reserved
                          ?.filter((r: any) => r.waitList)
                          .reduce(
                            (curr: number, r: any) => curr + r.numShares,
                            0
                          ) || 0
                      }
                      amount={waitlistedPortfolioValue}
                      id={investment.id}
                    />
                  )
              )}
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Portfolio;
