import {
  IonButton,
  IonButtons,
  IonContent,
  IonIcon,
  IonImg,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { downloadOutline } from "ionicons/icons";
import { UserFile } from "../types/userFile.type";
import { PDFViewer } from "./PDFviewer";

type Props = {
  file: Blob;
  fileObject: UserFile;
  onDismiss: () => void;
};
export const FileModal: React.FC<Props> = (props) => {
  const { file, fileObject, onDismiss } = props;
  const downloadFile = () => {
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(file);
    link.download = `tmp-${+new Date()}.${file.type.split("/")[1]}`;
    link.click();
  };
  return (
    <IonContent className="ion-padding">
      <IonToolbar>
        <IonTitle>{fileObject.name}</IonTitle>
        <IonButtons slot="start">
          <IonButton onClick={onDismiss}>Close</IonButton>
        </IonButtons>
        <IonButtons slot="end">
          <IonButton onClick={downloadFile}>
            <IonIcon icon={downloadOutline} />
          </IonButton>
        </IonButtons>
      </IonToolbar>
      <div>
        {!file && (
          <div className="flex justify-center items-center">
            <IonSpinner color="primary" />
          </div>
        )}
        {file?.type === "application/pdf" && <PDFViewer file={file} />}
        {["image/jpeg", "image/png"].indexOf(file?.type) !== -1 && (
          <IonImg src={URL.createObjectURL(file)} alt="file" />
        )}
        {/*  TODO: Cater for other file types (csv, videos, etc)   */}
      </div>
    </IonContent>
  );
};
