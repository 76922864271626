import { getApp, getApps, initializeApp } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  signInWithPhoneNumber,
} from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  getFirestore,
  onSnapshot,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAZ8tDA7qXaU-2Z_LR8fGuTNdOUwrPktvU",
  authDomain: "property-investment-fa2a5.firebaseapp.com",
  projectId: "property-investment-fa2a5",
  storageBucket: "property-investment-fa2a5.appspot.com",
  messagingSenderId: "119756884085",
  appId: "1:119756884085:web:ce33b54b238414c7ff903c",
  measurementId: "G-4PXPTKGENE",
};

const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();

export const auth = getAuth(app);

export const firestore = getFirestore(app);
export const storage = getStorage(app);

const googleProvider = new GoogleAuthProvider();

export const signInWithPhone = async (
  phoneNumber: string,
  appVerifier: any
) => {
  // replace starting 0 with +1
  if (phoneNumber.startsWith("65")) {
    phoneNumber = "+1" + phoneNumber;
  } else {
    phoneNumber = phoneNumber.replace(/^0/, "+27");
  }
  // phoneNumber = "+16505553434"; // TEST PURPOSE -> CODE is 123456
  const confirmationResult = await signInWithPhoneNumber(
    auth,
    phoneNumber,
    appVerifier
  );
  return confirmationResult;
};

export const verifyCode = async (code: string, confirmationResult: any) => {
  try {
    const res = await confirmationResult.confirm(code);
    const user = res.user;
    const docRef = doc(firestore, "users", user.uid);
    const docData = await getDoc(docRef);
    if (!docData.data()) {
      await setDoc(doc(firestore, "users", user.uid), {
        authProvider: "phone",
        phoneNumber: user.phoneNumber.replace("+27", "0").replace("+1", ""),
      });
    }
    return { verified: true, message: "Signed in successfully" };
  } catch (err: any) {
    console.error(err);
    if (err.code === "auth/invalid-verification-code") {
      return { verified: false, message: "Invalid code" };
    } else {
      return { verified: false, message: "Something went wrong" };
    }
  }
};

export const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const docRef = doc(firestore, "users", user.uid);
    const docData = await getDoc(docRef);
    if (!docData.data()) {
      await setDoc(doc(firestore, "users", user.uid), {
        firstName: user.displayName,
        authProvider: "google",
        email: user.email,
        phoneNumber: user.phoneNumber,
        photoURL: user.photoURL,
        username: user.displayName,
        emailVerified: user.emailVerified,
      });
    }
  } catch (err: any) {
    console.error(err);
  }
};

export const onAuthStateChange = (callback: any) =>
  onAuthStateChanged(auth, (user) => {
    if (user) {
      callback(user);
    } else {
      callback(null);
    }
  });

export const getUserSnapshot = (docId: string, callback: any) => {
  const docRef = doc(firestore, "users", docId);
  return onSnapshot(docRef, (doc: any) => {
    if (doc.exists()) {
      callback({ id: doc.id, ...doc.data() });
    } else {
      callback(null);
    }
  });
};

export const getAssetValue = async (docId: string) => {
  const docRef = doc(firestore, "assets", docId);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    let data = docSnap.data();
    let images = data.images.map((img: any) =>
      getDownloadURL(ref(storage, img.path))
    );
    images = await Promise.all(images);
    data.images = images;

    return { id: docSnap.id, ...data };
  } else {
    return null;
  }
};

export const getAvailableInvestmentsSnapshot = (callback: any) => {
  const investmentsRef = query(
    collection(firestore, "investments"),
    where("closesAt", ">", new Date().toISOString())
  );
  return onSnapshot(investmentsRef, (querySnapshot: any) => {
    const investments: any = [];
    querySnapshot.forEach((doc: any) => {
      investments.push({ id: doc.id, ...doc.data() });
    });
    callback(investments);
  });
};

export const signOutAuth = async () => {
  try {
    await signOut(auth);
  } catch (err: any) {
    console.error(err);
  }
};

export const updateDocument = async (
  collectionName: string,
  docId: string,
  data: any
) => {
  const docRef = doc(firestore, collectionName, docId);
  await setDoc(docRef, data, { merge: true });
};

export const logInWithEmailAndPassword = async (
  email: string,
  password: string
) => {
  // TODO: If first sign in, send email verification firebase
  try {
    // if(auth.currentUser?.emailVerified === false) {
    //   await auth.currentUser.sendEmailVerification();
    // }
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err: any) {
    await registerWithEmailAndPassword(email, password);
  }
};

export const registerWithEmailAndPassword = async (
  email: string,
  password: string
) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await setDoc(doc(firestore, "users", user.uid), {
      authProvider: "local",
      email: user.email,
    });
  } catch (err: any) {
    console.error(err);
  }
};

export const sendPasswordReset = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (err: any) {
    console.error(err);
  }
};
