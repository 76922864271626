import { collection, onSnapshot, query, where } from "firebase/firestore";
import create from "zustand";
import { auth, firestore } from "../lib/firebase";
import { Transaction } from "../types/transaction.type";

interface TransactionsState {
  transactions: Transaction[];
  fetching: boolean;
  getTransaction: (id: string) => Transaction | undefined;
}

export const useTransactionsStore = create<TransactionsState>((set, get) => ({
  transactions: [],
  fetching: true,
  getTransaction: (id) => get().transactions.find((t) => t.id === id),
}));

setTimeout(() => {
  auth.onAuthStateChanged(async (user) => {
    if (user) {
      onSnapshot(
        query(
          collection(firestore, "transactions"),
          where("user", "==", user.uid)
        ),
        (querySnapshot) => {
          useTransactionsStore.setState({ fetching: true });
          let tempTransactions: Transaction[] = [];
          querySnapshot.forEach((doc) => {
            tempTransactions.push(toTransaction(doc));
          });
          useTransactionsStore.setState({
            transactions: tempTransactions,
            fetching: false,
          });
        }
      );
    }
  });
}, 1000);
function toTransaction(doc: any): Transaction {
  let transaction: any = { ...doc.data(), id: doc.id };
  transaction.timestamp = transaction.timestamp?.toDate();
  if (transaction.status) {
    transaction.status.payment.updated =
      transaction.status?.payment?.updated?.toDate();
    transaction.status.documentation.updated =
      transaction.status?.documentation?.updated?.toDate();
  }
  return transaction as Transaction;
}
