import { IonCard } from "@ionic/react";
import { Investment } from "../types/investment.type";
import moment from "moment";
import { toCurrency } from "../lib/utils";

const InvestmentCard: React.FC<{ investment: Investment }> = ({
  investment,
}) => {
  const statusColors = {
    open: "bg-light-blue",
    listed: "bg-beige",
    active: "bg-white-beige",
    completed: "bg-grey-blue",
    draft: "",
    closed: "bg-grey-blue",
    cancelled: "",
  };

  const statusMap: any = {
    listed: "Coming soon",
    open: "Open",
  };
  const statusColor = statusColors[investment.status];
  return (
    <IonCard
      className="bg-white w-full mx-auto text-grey-blue rounded-2xl p-4"
      button
      routerLink={`/investments/${investment.id}`}
    >
      <div className="relative">
        <div
          className={
            "absolute top-0 right-0 text-black p-1 text-sm rounded-md m-2 " +
            statusColor
          }
        >
          {statusMap[investment.status] || investment.status}
        </div>
        <img
          src="https://images.pexels.com/photos/1127119/pexels-photo-1127119.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" //TEST IMAGE
          alt={investment.name}
          className="w-full h-16 object-cover rounded-xl"
        />
      </div>
      <h2 className="text-headline text-xl text-dark-grey mt-4 mb-1">
        {investment.name}
      </h2>
      {/* <div className="text-sm">{investment?.description}</div> */}
      <div className="text-sm">Opportunity to become a co-owner.</div>
      <div className="text-error mt-2">
        Closes{" "}
        <span className="text-primary">
          {moment(investment.closesAt).fromNow()}
        </span>
      </div>
      <div className="flex justify-between mt-8 items-center text-dark-grey">
        <div>
          <div className="text-xs">Annual Yield</div>
          <div className="text-headline text-base text-grey-blue">
            {investment.yield}%
          </div>
        </div>
        <div className="text-right">
          <div className="text-sm">
            <span className="font-semi-bold">
              {investment.availableShares}/{investment.totalShares}
            </span>{" "}
            shares left at
          </div>
          <div className="text-grey-blue text-headline text-base">
            {toCurrency(investment.shareValue)}
          </div>
        </div>
      </div>
    </IonCard>
  );
};

export default InvestmentCard;
