import {
  IonButton,
  IonCard,
  IonIcon,
  IonList,
  IonSpinner,
  IonText,
} from "@ionic/react";
import { RecaptchaVerifier } from "firebase/auth";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import logo from "../assets/logo/Logo_deep.svg";
import pattern from "../assets/pattern.svg";
import { auth, signInWithPhone, verifyCode } from "../lib/firebase";
import { useAuthStore } from "../stores/auth.store";
import Layout from "./Layout";
import TextInput from "./forms/TextInput";
import getValidationSchema from "./getValidationSchema";
import validate from "./validate";
import { callOutline } from "ionicons/icons";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const signInValues = {
  phoneNumber: "",
  verificationCode: "",
};

const getErrorMessageText = (props: any, fieldName: string | number) => (
  <IonText
    className="error ion-padding-horizontal text-xs flex justify-self-start"
    color="danger"
  >
    {props.touched[fieldName] && props.errors[fieldName]}
  </IonText>
);

export default function SignInFormContainer() {
  const [showPhoneSignIn, setShowPhoneSignIn] = useState(false);
  const whitelist = useAuthStore((state) => state.whitelist);
  const [appVerified, setAppVerified] = useState<any>(null);
  const [appVerifier, setAppVerifier] = useState<any>(null);
  const [confirmationResult, setConfirmationResult] = useState<any>(null);
  const onSubmit = async (values: any, { setSubmitting, setErrors }: any) => {
    try {
      const numbers = whitelist.map((item: any) => item.id);

      values.phoneNumber = values.phoneNumber.replace(/ /g, "");
      values.phoneNumber = values.phoneNumber.replace("+27", "0");
      values.phoneNumber = values.phoneNumber.replace("+", "");

      if (!numbers.includes(values.phoneNumber)) {
        toast.error(
          "You are not authorized to use this app. Please contact us for enrollment."
        );
        return;
      }
      setConfirmationResult(
        await signInWithPhone(values.phoneNumber, appVerifier)
      );
      toast.success("SMS with verification code sent");
    } catch (e) {
      console.error(e);
    }
  };

  const initRecaptcha = async () => {
    try {
      // @ts-ignore
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "normal",
          callback: (response: any) => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            setAppVerified(true);
          },
          "expired-callback": () => {
            // Response expired. Ask user to solve reCAPTCHA again.
            console.error("expired-callback");
            setAppVerified(false);
          },
        },
        auth
      );
      // @ts-ignore
      window.recaptchaWidgetId = await window.recaptchaVerifier.render();
      // @ts-ignore
      setAppVerifier(window.recaptchaVerifier);
    } catch (error) {
      console.error(error);
    }
  };

  const confirmCode = async (code: any) => {
    const resp = await verifyCode(code, confirmationResult);
    if (resp.verified) {
      toast.success(resp.message);
    } else {
      toast.error(resp.message);
    }
  };

  useEffect(() => {
    if (showPhoneSignIn) initRecaptcha();
  }, [showPhoneSignIn]);

  return (
    <Layout hideNavBar hideToolbar>
      <div className="relative w-full pt-36 pb-12">
        <img src={pattern} alt="pattern" className="absolute inset-0 w-full" />
        <img src={logo} alt="logo" className="relative mx-auto w-64" />
      </div>
      <div className="text-headline text-grey-blue text-xl w-full px-20 text-center mb-20">
        Invest smart, invest in properties, peer-to-peer
      </div>
      {!showPhoneSignIn && (
        <div>
          <IonButton
            className="w-full mb-4 px-8"
            expand="block"
            onClick={() => setShowPhoneSignIn(true)}
          >
            <IonIcon slot="start" icon={callOutline} />
            Sign in with Phone
          </IonButton>
          <p className="text-center py-8 px-4">
            We authenticate using your phone number and a OTP code. If you are
            not enrolled, please{" "}
            <span
              className="font-bold capitalize underline cursor-pointer hover:text-opacity-70"
              onClick={() => setShowPhoneSignIn(true)}
              style={{ textDecoration: "none", transition: "color 0.3s" }}
            >
              Sign Up
            </span>{" "}
            here.
          </p>
        </div>
      )}
      {showPhoneSignIn && (
        <Formik
          initialValues={signInValues}
          onSubmit={onSubmit}
          validate={validate(getValidationSchema)}
        >
          {(props) => (
            <IonCard>
              <IonList className="px-4 bg-white">
                <form onSubmit={props.handleSubmit}>
                  {!confirmationResult && (
                    <div className="p-3 text-lg">
                      <p className="py-2">Enter your cellphone number</p>
                      <PhoneInput
                        international
                        defaultCountry="ZA"
                        value={props.values.phoneNumber}
                        onChange={(value) =>
                          props.setFieldValue("phoneNumber", value)
                        }
                        name="phoneNumber"
                        label="Phone Number*"
                        placeholder="Enter phone number"
                        className="rounded border text-xl border-slate-300 shadow-lg bg-white p-2 w-full"
                      />
                      <p>{getErrorMessageText(props, "phoneNumber")}</p>
                      <div
                        id="recaptcha-container"
                        className="justify-center flex p-4 h-24"
                      ></div>
                    </div>
                  )}
                  {confirmationResult && (
                    <div className="p-3 ">
                      <p className="py-2 text-lg">
                        Enter your verification code
                      </p>
                      <TextInput
                        name="verificationCode"
                        label="Verification Code*"
                        placeholder="000000"
                        type="text"
                      />
                    </div>
                  )}

                  <div className="flex justify-end">
                    {confirmationResult && (
                      <IonButton
                        onClick={() =>
                          confirmCode(props.values.verificationCode)
                        }
                        className="m-4"
                        disabled={!props?.values?.verificationCode}
                      >
                        VERIFY CODE
                      </IonButton>
                    )}
                    {!confirmationResult && (
                      <div className="w-full">
                        <IonButton
                          type="submit"
                          expand="block"
                          size="large"
                          fill="solid"
                          disabled={
                            !appVerified ||
                            !props?.values?.phoneNumber ||
                            props.isSubmitting
                          }
                        >
                          {props.isSubmitting ? <IonSpinner /> : "SIGN IN"}
                        </IonButton>
                        <div className="flex justify-center">
                          <p className="p-4 w-[240px] text-center">
                            Once the Captcha is verified, you will be able to
                            Sign In
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </form>
              </IonList>
            </IonCard>
          )}
        </Formik>
      )}
    </Layout>
  );
}
