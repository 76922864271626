import {
  IonButton,
  IonCard,
  IonCheckbox,
  IonContent,
  IonFooter,
  IonIcon,
  IonLabel,
  IonModal,
  IonToolbar,
} from "@ionic/react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router";
import InvestmentTimeline from "../components/InvestmentTimeline";
import Layout from "../components/Layout";
import { toCurrency } from "../lib/utils";
import { useAuthStore } from "../stores/auth.store";
import { useInvestmentsStore } from "../stores/investments.store";
import { useTransactionsStore } from "../stores/transactions.store";
import { addOutline, removeOutline } from "ionicons/icons";
import InvestmentCard from "../components/InvestmentCard";

const InvestmentSingle: React.FC = () => {
  const [acceptDisclaimer, setAcceptDisclaimer] = useState(false);
  const reserveShares = useInvestmentsStore((state) => state.reserveShares);
  const [investModalIsOpen, setInvestModalIsOpen] = useState(false);
  const [numberOfSharesTOInvest, setNumberOfSharesToInvest] = useState(1);
  const [uid, user] = useAuthStore((state) => [
    state.auth?.uid || "",
    state.user,
  ]);
  const { id } = useParams<{ id: string }>();
  const [investmentId, setInvestmentId] = useState(id);

  const transactions = useTransactionsStore((state) =>
    state.transactions.filter((t) => t.investment === investmentId)
  );
  const investment = useInvestmentsStore((state) =>
    state.allInvestments.find((i) => i.id === investmentId)
  );

  useEffect(() => {
    if (!id) {
      const tmpId = window.location.href.split("/").slice(-1)[0];
      setInvestmentId(tmpId);
    }
  }, [id]);

  if (!investment) return null;

  const reservations = investment?.reserved.filter((r) => r.user === uid);

  const numSharesReserved =
    reservations?.reduce((curr, r) => {
      if (r.waitList) return curr;
      else return curr + r.numShares;
    }, 0) || 0;

  const numSharesOnWaitList =
    reservations?.reduce((curr, r) => {
      if (!r.waitList) return curr;
      else return curr + r.numShares;
    }, 0) || 0;

  const numberOfShares = transactions.reduce(
    (curr, t) => curr + t.numShares,
    0
  );

  const percentageFull = Math.round(
    (1 - investment.availableShares / investment.totalShares) * 100
  );
  const statusColors = {
    open: "bg-light-blue",
    listed: "bg-beige",
    active: "bg-white-beige",
    completed: "bg-grey-blue",
    draft: "bg-cool-grey",
    closed: "bg-green-200",
    cancelled: "bg-red-200",
  };
  const statusMap: any = {
    listed: "Coming soon",
    open: "Open",
    closed: "In Progress",
  };
  const statusColor = statusColors[investment.status];
  const canInvest =
    investment.status === "open" || investment.status === "closed";
  const isWaitlist =
    (investment.availableShares === 0 && investment?.status === "open") ||
    investment?.status === "closed";
  const changeSharesToInvest = (num: number) => {
    if (num < 1) num = 1;
    if (!isWaitlist) {
      num = Math.min(num, investment.availableShares);
    } else if (isWaitlist) {
      num = Math.min(num, investment.totalShares);
    }
    setNumberOfSharesToInvest(num);
  };

  const totalRaised =
    (investment.totalShares - investment.availableShares) *
    investment.shareValue;

  const timelineData = [
    {
      title: "Open",
      date: investment.opensAt.toDateString(),
      metadata: "Investment opens for share purchase",
    },
    {
      title: "Close",
      date: investment.closesAt.toDateString(),
      metadata: "Investment opurtunity closes",
    },
    ...(reservations ?? []).map((reservation) => ({
      title: `You reserved ${reservation.numShares} shares`,
      // @ts-ignore
      date: new Date(reservation.timestamp.seconds * 1000).toDateString(),
      metadata: reservation.waitList
        ? "Waitlisted and under review"
        : "Processed by admins",
      confirmed: !reservation.waitList,
    })),
    ...(transactions ?? []).map((transaction) => ({
      title: `You received ${transaction.numShares} shares`,
      // @ts-ignore
      date: transaction.timestamp.toDateString(),
      metadata: "Transaction completed.",
    })),
  ].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

  // console.log("timelineData", timelineData);

  return (
    <Layout title={investment.name} backButton>
      <div className="px-4 text-grey pt-4">
        <div className="flex justify-between items-center text-black">
          <div className="section-heading">Information</div>
          <div className={"rounded-md px-4 py-1 " + statusColor}>
            {statusMap[investment.status] || investment.status}
          </div>
        </div>

        <IonCard className="section-card">
          <div className="text-center">
            <div>Annual yield</div>
            <div className="text-headline text-4xl text-dark-grey mt-2 mb-4">
              {investment.yield}%
            </div>
          </div>
          <div className="flex">
            <div className="w-2/3">
              <div>Number of assets:</div>
              <div>Total value:</div>
              <div>Number of shares:</div>
              <div>Value of 1 share:</div>
            </div>
            <div className="text-grey-blue">
              <div>{investment.assets.length}</div>
              <div>{toCurrency(investment.totalValue)}</div>
              <div>{investment.totalShares}</div>
              <div>{toCurrency(investment.shareValue)}</div>
            </div>
          </div>
        </IonCard>
        {investment.description && (
          <div>
            <div className="section-heading">Description</div>
            <IonCard className="section-card">{investment.description}</IonCard>
          </div>
        )}
        <div className="section-heading flex items-center justify-between pt-2">
          Status
          <div className="flex justify-center">
            <p className="rounded-xl bg-grey-blue text-white px-4 py-1 text-sm">
              You have{" "}
              {numberOfShares
                ? numberOfShares
                : `${numSharesReserved} reserved`}{" "}
              shares
            </p>
          </div>
        </div>
        <IonCard className="section-card">
          {isWaitlist ? (
            <div className="text-center">
              <p className="font-semibold">
                This investment is currently full!
              </p>
              <p>You can still reserve shares to be added to the waitlist.</p>
            </div>
          ) : (
            <div className="text-center">{percentageFull}% full</div>
          )}
          <div className="w-full rounded-full h-4 flex bg-light-grey my-2">
            <div
              className={
                "bg-light-blue h-full rounded-full text-center text-xs"
              }
              style={{ width: `${percentageFull}%` }}
            ></div>
          </div>
          <div className="flex justify-between text-base text-grey-blue text-headline">
            <div>{toCurrency(totalRaised || 0)}</div>
            of
            <div>{toCurrency(investment.totalValue)}</div>
          </div>
        </IonCard>
        <div className="section-heading">Assets</div>
        <div>
          {investment.assets.map((a) => (
            <IonCard
              key={"asset" + Math.random() + a.id}
              className="section-card"
              routerLink={`/investments/${investment.id}/assets/${a.id}`}
            >
              <div className="flex items-stretch">
                <img
                  src={a.images[0]}
                  className="w-36 h-20 object-cover rounded-lg flex-shrink-0"
                  alt={a.name}
                />
                <div className="flex-shrink ml-4">
                  <div>{a.name}</div>
                  <div className="text-headline text-base text-grey-blue mt-4">
                    {toCurrency(a.value)}
                  </div>
                </div>
              </div>
            </IonCard>
          ))}
        </div>
        <div className="section-heading">Timeline</div>
        <InvestmentTimeline items={timelineData} />
        <div className="text-center mb-2 pt-2">
          {investment?.status === "listed" && <div>Coming soon!</div>}
          <IonButton
            // onClick={() => setInvestModalIsOpen(true)}
            disabled={!canInvest}
            onClick={() => {
              if (user?.verificationStatus !== "verified") {
                // Display error message if user is not verified
                toast.error(
                  "You cannot invest until your account is verified. Please contact administrator."
                );
              } else {
                // Proceed as before if user is verified
                setInvestModalIsOpen(true);
              }
            }}
            className="w-full"
          >
            {isWaitlist ? "Join waitlist" : "Invest now"}
          </IonButton>
        </div>
        <div className="mb-10">
          {/* {numSharesReserved !== 0 &&
            (investment.status === "open" ||
              investment.status === "closed") && (
              <div className="text-center text-lg">
                You reserved <span>{numSharesReserved}</span> shares of this
                investment
                <div>
                  <IonRouterLink routerLink={`/portfolio/${investment.id}`}>
                    view portfolio
                  </IonRouterLink>
                </div>
              </div>
            )} */}
          {investment.status === "open" && numSharesOnWaitList !== 0 && (
            <div className="text-center text-lg">
              You have <span>{numSharesOnWaitList}</span> shares of this
              investment on the waiting list
            </div>
          )}
        </div>
      </div>

      <IonModal
        isOpen={investModalIsOpen}
        onWillDismiss={() => setInvestModalIsOpen(false)}
      >
        <IonContent>
          <div className="px-4 pt-8">
            <div className="text-headline text-xl text-dark-grey mb-4">
              Reserve Shares {isWaitlist && "to join waitlist"}
            </div>
            <InvestmentCard investment={investment} />
            <div className="text-center bg-white rounded-2xl py-2">
              <p className="text-base">Number of shares:</p>
              <div className="flex justify-center items-center my-4">
                <IonIcon
                  icon={removeOutline}
                  className="text-xl bg-deep-blue text-white rounded-full p-2 mr-4"
                  onClick={() =>
                    changeSharesToInvest(numberOfSharesTOInvest - 1)
                  }
                />
                <div className="text-headline text-deep-blue text-3xl mx-4">
                  {numberOfSharesTOInvest}
                </div>
                <IonIcon
                  icon={addOutline}
                  className="text-xl bg-deep-blue text-white rounded-full p-2 ml-4"
                  onClick={() =>
                    changeSharesToInvest(numberOfSharesTOInvest + 1)
                  }
                />
              </div>
            </div>
            <div className="p-4 text-base border-t border-grey border-opacity-25">
              <div className="flex justify-between">
                <div>
                  <span className="text-deep-blue">
                    {numberOfSharesTOInvest}
                  </span>{" "}
                  x shares
                </div>
                <div className="text-deep-blue">
                  {toCurrency(investment.shareValue)}
                </div>
              </div>
              <div className="flex justify-end mt-2">
                Total:
                <div className="text-deep-blue ml-4">
                  {toCurrency(investment.shareValue * numberOfSharesTOInvest)}
                </div>
              </div>
            </div>
            <div className="p-4">
              <div className="text-sm mb-4">
                <strong>Important Disclaimer:</strong>
                <p>
                  Please read and accept the terms before confirming your
                  investment.
                </p>
              </div>
              <div className="flex justify-between items-center px-8">
                <IonLabel>
                  <p>I accept the terms and conditions.</p>
                </IonLabel>
                <IonCheckbox
                  checked={acceptDisclaimer}
                  onIonChange={(e) => setAcceptDisclaimer(e.detail.checked)}
                />
              </div>
            </div>
          </div>
        </IonContent>
        <IonFooter>
          <IonToolbar>
            <IonButton
              disabled={!acceptDisclaimer || !canInvest}
              onClick={() => {
                const investPromise = reserveShares(
                  investment.id,
                  numberOfSharesTOInvest,
                  uid,
                  isWaitlist
                ).then(() => setInvestModalIsOpen(false));
                toast.promise(investPromise, {
                  loading: "Reserving...",
                  success: "Reserved!",
                  error: "Could not complete the reservation",
                });
              }}
              slot="end"
            >
              Confirm
            </IonButton>
            <IonButton fill="clear" onClick={() => setInvestModalIsOpen(false)}>
              Cancel
            </IonButton>
          </IonToolbar>
        </IonFooter>
      </IonModal>
    </Layout>
  );
};
export default InvestmentSingle;
