import { MIN_PASSWORD_LENGTH, MIN_USERNAME_LENGTH } from "./const";

export function getBasicInformationValidationSchema(values: any) {
  return {
    firstName: [[(value: any) => !isEmpty(value), "First name is required!"]],
    lastName: [[(value: any) => !isEmpty(value), "Last name is required!"]],
    phoneNumber: [
      [(value: any) => !isEmpty(value), "Phone number is required!"],
    ],
    email: [
      [(value: any) => !isEmpty(value), "E-mail is required!"],
      [(value: any) => isEmail(value), "E-mail is not valid!"],
    ],
    olderThan18: [
      [(value: any) => value, "You must be at least 18 years old!"],
    ],
    consent: [
      [(value: any) => value, "You must accept the terms and conditions"],
    ],
  };
}

export default function getValidationSchema(values: any) {
  return {
    username: [
      [(value: any) => !isEmpty(value), "Username is required!"],
      [
        (value: any) => value.length >= MIN_USERNAME_LENGTH,
        `Username has to be longer than ${MIN_USERNAME_LENGTH} characters!`,
      ],
    ],
    email: [
      [(value: any) => !isEmpty(value), "E-mail is required!"],
      [(value: any) => isEmail(value), "E-mail is not valid!"],
    ],
    password: [
      [(value: any) => !isEmpty(value), "Password is required!"],
      [
        (value: any) => value.length >= MIN_PASSWORD_LENGTH,
        `Password has to be longer than ${MIN_PASSWORD_LENGTH} characters!`,
      ],
    ],
    consent: [
      [
        (value: any) => value === true,
        "You have to give consent for us to access your Personal Information!",
      ],
    ],
    confirmTerms: [
      [
        (value: any) => value === true,
        "You have to agree with our Terms and Conditions!",
      ],
    ],
    title: [
      [(value: any) => !isEmpty(value), "Title is required!"],
      // TODO: one of the following Mr. Mrs Ms. Dr. etc
    ],
    firstName: [[(value: any) => !isEmpty(value), "First name is required!"]],
    initials: [[(value: any) => !isEmpty(value), "Initials are required!"]],
    lastName: [[(value: any) => !isEmpty(value), "Last name is required!"]],
    idNumber: [
      [(value: any) => !isEmpty(value), "ID number is required!"],
      [(value: any) => isValidIdNumber(value), "ID number not valid!"],
    ],
    dateOfBirth: [
      [(value: any) => !isEmpty(value), "Date of birth is required!"],
      // TODO: Valid date
    ],
    country: [[(value: any) => !isEmpty(value), "Country is required!"]],
    gender: [[(value: any) => !isEmpty(value), "Gender is required!"]],
    phoneNumber: [
      [(value: any) => !isEmpty(value), "Phone number is required!"],
    ],
    address: [[(value: any) => !isEmpty(value), "Address is required!"]],
    // suburb: [[(value: any) => !isEmpty(value), "Suburb is required!"]],
    city: [[(value: any) => !isEmpty(value), "City is required!"]],
    postalCode: [[(value: any) => !isEmpty(value), "Postal code is required!"]],
  };
}

export function getVerificationValidationSchema(values: any) {
  return {
    entityType: [[(value: any) => !isEmpty(value), "Entity type is required!"]],
    registrationNumber: [
      [
        (value: any) => !(values.entityType === "company" && isEmpty(value)),
        "Registration number is required!",
      ],
    ],
    idNumber: [
      [(value: any) => !isEmpty(value), "ID number is required!"],
      [(value: any) => isValidIdNumber(value), "ID number not valid!"],
    ],
    address: [[(value: any) => !isEmpty(value), "Address is required!"]],
    // representative: {
    //   firstName: [[(value: any) => !(values.entityType === "company" && !values.sameRepresentative && isEmpty(value)), "First name is required!"]],
    //   lastName: [[(value: any) => !(values.entityType === "company" && !values.sameRepresentative && isEmpty(value)), "Last name is required!"]],
    //   phoneNumber: [[(value: any) => !(values.entityType === "company" && !values.sameRepresentative && isEmpty(value)), "Phone number is required!"]],
    // }
  };
}

function isValidIdNumber(value: any) {
  return value.toString().length === 13;
}

function isEmpty(value: any) {
  return !value || !value.trim();
}

function isEmail(value: any) {
  const EMAIL_REGEXP = /([\w\.\-_]+)?\w+@[\w-_]+(\.\w+){1,}/; // eslint-disable-line no-useless-escape
  return EMAIL_REGEXP.test(value);
}
