import { IonIcon, IonSearchbar, IonSpinner } from "@ionic/react";
import { filter } from "ionicons/icons";
import { useState } from "react";
import InvestmentCard from "../components/InvestmentCard";
import Layout from "../components/Layout";
import { useInvestmentsStore } from "../stores/investments.store";

const Investments: React.FC = () => {
  const [investments, loading] = useInvestmentsStore((state) => [
    state.allInvestments.filter((investment) => investment?.status !== "draft"),
    state.loading,
  ]);
  const [query, setQuery] = useState("");
  const filteredInvestments = investments.filter((investment) =>
    investment.name.toLowerCase().includes(query.toLowerCase())
  );

  return (
    <Layout title="Marketplace" hideToolbar>
      <div className="px-4 pt-8">
        <div className="text-black text-headline text-2xl pb-4">
          Marketplace
        </div>
        <div className="flex justify-between items-center">
          <IonSearchbar
            className="marketplace"
            onIonChange={(e) => {
              setQuery(e.detail.value!);
            }}
          />
          <IonIcon
            icon={filter}
            className="bg-white text-deep-blue rounded-full p-2 w-6 h-6"
          />
        </div>
        {loading ? (
          <div className="flex items-center justify-center">
            <IonSpinner color="primary" className="w-8 h-8" />
          </div>
        ) : (
          <div>
            {filteredInvestments.length === 0 && (
              <div className="text-center mt-5">
                <p className="text-lg py-2">
                  {investments.length > 0
                    ? "No investments found for your search"
                    : "No investments available at the moment..."}
                </p>
              </div>
            )}
            {filteredInvestments.map((investment: any, index: number) => (
              <InvestmentCard investment={investment} key={index} />
            ))}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Investments;
