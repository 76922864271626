import {
  IonButton,
  IonCard,
  IonIcon,
  IonItem,
  IonList,
  IonNote,
  IonSpinner,
} from "@ionic/react";
import { Formik } from "formik";
import { checkmarkCircleOutline, closeCircleOutline } from "ionicons/icons";
import { toast } from "react-hot-toast";
import CheckInput from "../components/forms/CheckInput";
import FileInput from "../components/forms/FileInput";
import SelectInput from "../components/forms/SelectInput";
import TextInput from "../components/forms/TextInput";
import { getVerificationValidationSchema } from "../components/getValidationSchema";
import Layout from "../components/Layout";
import validate from "../components/validate";
import { updateDocument } from "../lib/firebase";
import { useAuthStore } from "../stores/auth.store";

const Verification: React.FC = () => {
  const user = useAuthStore((state) => state.user);
  const auth = useAuthStore((state) => state.auth);
  if (!auth) return null;
  return (
    <Layout title="Verification" hideNavBar backButton>
      {!user.verificationStatus && <VerificationForm />}
      {user.verificationStatus === "pending" && <VerificationPending />}
      {user.verificationStatus === "verified" && (
        <div className="p-8 flex justify-center items-center">
          <img
            src="/verified.png"
            alt="verified"
            className="rounded-xl shadow-lg"
          />
        </div>
      )}
    </Layout>
  );
};

export default Verification;

const VerificationForm: React.FC = () => {
  const auth = useAuthStore((state) => state.auth);
  if (!auth) return null;
  return (
    <IonCard className="border p-2 shadow-xl">
      <Formik
        initialValues={{
          entityType: "individual",
          registrationNumber: "",
          sameRepresentative: true,
          representative: {
            firstName: "",
            lastName: "",
            phoneNumber: "",
          },
          idNumber: "",
          address: "",
          files: [],
        }}
        onSubmit={async (values) => {
          const temp = updateDocument("users", auth.uid, {
            verificationStatus: "pending",
            ...values,
          });
          toast.promise(temp, {
            loading: "Submitting",
            error: "Something went wrong",
            success: "Submitted for verification",
          });
          await temp;
        }}
        validate={validate(getVerificationValidationSchema)}
      >
        {({ values, submitForm, isSubmitting }) => (
          <form>
            <SelectInput
              name="entityType"
              label="Entity type"
              options={[
                { label: "Individual", value: "individual" },
                { label: "Company", value: "company" },
              ]}
            />
            {values.entityType === "company" && (
              <>
                <TextInput
                  name="registrationNumber"
                  label="Registration Number"
                  placeholder="2015/123456/07"
                />
                <CheckInput
                  name="sameRepresentative"
                  label="The respresentative of the entity is the same person that is linked  to this account"
                />
                {!values.sameRepresentative && (
                  <>
                    <div className="text-xl pl-2">Representative Info</div>
                    <div className="pl-4">
                      <TextInput
                        name="representative.firstName"
                        label="First Name"
                        placeholder="Example"
                      />
                      <TextInput
                        name="representative.lastName"
                        label="Last Name"
                        placeholder="Example"
                      />
                      <TextInput
                        name="representative.phoneNumber"
                        label="Phone Number"
                        placeholder="000 000 0000"
                      />
                    </div>
                  </>
                )}
              </>
            )}
            <TextInput
              name="idNumber"
              label="ID Number"
              placeholder="0101010101010"
            />
            <TextInput
              name="address"
              label="Address"
              placeholder="01 Example street Pretoria"
              textArea
            />
            <div className="px-2">
              Please upload:
              <ul className="px-2">
                {(values.entityType === "individual"
                  ? ["ID"]
                  : [
                      "Certification Of Incorporation",
                      "ID's of directors",
                      "CIPC",
                    ]
                ).map((val, index) => (
                  <li key={index}>{val}</li>
                ))}
              </ul>
            </div>
            <FileInput
              name="files"
              label="Files"
              filePath={`users/${auth.uid}/`}
            />
            <div className="text-center">
              <IonButton onClick={submitForm} disabled={isSubmitting}>
                {isSubmitting ? <IonSpinner /> : "Submit for verification"}
              </IonButton>
            </div>
          </form>
        )}
      </Formik>
    </IonCard>
  );
};

const VerificationPending: React.FC = () => {
  const user = useAuthStore((state) => state.user);
  const VerificationItem = ({ label, status }: any) => (
    <IonItem>
      {label}
      <IonNote slot="end">
        <div className="flex items-center">
          Status
          <IonIcon
            icon={status ? checkmarkCircleOutline : closeCircleOutline}
            className="text-xl ml-2"
            color={status ? "green" : "red"}
          />
        </div>
      </IonNote>
    </IonItem>
  );
  return (
    <div>
      <div className="text-center">Your verification is being processed</div>
      <IonList>
        <VerificationItem
          label="Information"
          status={user.verification?.information}
        />
        {user.entityType === "individual" ? (
          <>
            <VerificationItem label="ID" status={user.verification?.id} />
          </>
        ) : (
          <>
            <VerificationItem
              label="Certification of incorporation"
              status={user.verification?.certification}
            />
            <VerificationItem
              label="ID's of directors"
              status={user.verification?.directors}
            />
            <VerificationItem label="CIPC" status={user.verification?.cipc} />
          </>
        )}
      </IonList>
    </div>
  );
};
