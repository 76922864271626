interface InvestmentTimelineProps {
  items: { date: string; title: string; metadata: string }[];
}

const InvestmentTimeline: React.FC<InvestmentTimelineProps> = ({ items }) => {
  return (
    <>
      <ol className="relative border-deep-blue h-fit pl-6 mb-4">
        <div className="w-[2px] h-full bg-deep-blue absolute -ml-[1rem]"></div>
        {items.map((item, index) => (
          <li
            key={index}
            className="mb-3 bg-white flex items-center rounded-r-2xl ml-4 relative"
            style={{ paddingLeft: "1rem" }}
          >
            <div
              className="absolute -left-[3rem] top-0 flex items-center justify-center h-full"
              style={{ width: "2rem" }}
            >
              <div className="w-[2px] h-full bg-deep-blue ml-[2px]"></div>
              <div
                className="w-4 h-4 bg-deep-blue rounded-full border-2 border-white absolute"
                style={{ left: "0.6rem" }}
              ></div>
            </div>
            <div className="py-4 px-2 border-r border-light-grey text-left w-[100px]">
              <p className="mb-1 text-sm font-normal leading-none max-w-[100px] ">
                {item.date}
              </p>
            </div>
            <div className="py-4 pl-4">
              <div className="text-headline text-dark-grey">{item.title}</div>
              <div>{item.metadata}</div>
            </div>
          </li>
        ))}
      </ol>
    </>
  );
};

export default InvestmentTimeline;
