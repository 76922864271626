import create from "zustand";

interface AppBarStoreState {
  visible: boolean;
  show: (show: boolean) => void;
}

export const useAppBarStore = create<AppBarStoreState>((set, get) => ({
  visible: true,
  show: (show) => set({ visible: show }),
}));
