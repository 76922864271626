import { IonButton } from "@ionic/react";
import { toast } from "react-hot-toast";
import Layout from "../components/Layout";
import { signOutAuth } from "../lib/firebase";
import BasicInformationForm from "../components/BasicInformationForm";

const Profile: React.FC = () => {
  const logout = async () => {
    await signOutAuth();
    toast.success("Logged out successfully");
  };

  return (
    <Layout title="Profile" hideToolbar>
      <div className="px-4 pt-8">
        <div className="text-black text-headline text-2xl pb-4">Portfolio</div>
        <BasicInformationForm />
        <div className="text-center">
          <IonButton fill="solid" expand="block" onClick={logout}>
            Logout
          </IonButton>
        </div>
      </div>
    </Layout>
  );
};

export default Profile;
